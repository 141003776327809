import AddProduct from "pages/AddProduct";
import NightPrices from "pages/NightPrices";
import Priority from "pages/Priority";
import PriorityDetails from "pages/PriorityDetails";
import { lazy } from "react";

const AddScript = lazy(() => import("pages/AddScript"));
const AddRole = lazy(() => import("pages/AddRole"));
const AddQuotation = lazy(() => import("pages/AddQuotation"));
const AddZone = lazy(() => import("pages/AddZone"));
const AddClass = lazy(() => import("pages/AddClass"));
const AddModel = lazy(() => import("pages/AddModel"));
const AddCareer = lazy(() => import("pages/AddCareer"));
const AddDriver = lazy(() => import("pages/AddDriver"));
const AddBooking = lazy(() => import("pages/AddBooking"));
const AddVehicle = lazy(() => import("pages/AddVehicle"));
const AddPartner = lazy(() => import("pages/AddPartner"));
const AddCompany = lazy(() => import("pages/AddCompany"));
const AddPricing = lazy(() => import("pages/AddPricing"));
const AddCampaign = lazy(() => import("pages/AddCampaign"));
const AddNightPrice = lazy(() => import("pages/AddNightPrice"));
const AddSpecialPrice = lazy(() => import("pages/AddSpecialPrice"));
const SendPushNotification = lazy(() => import("pages/SendPushNotification"));
const AddRolesPermissions = lazy(
  () => import("pages/AddRolesPermissions/AddRolesPermissions")
);
const AddCompanyAccount = lazy(() => import("pages/AddCompanyAccount"));

const UpdateSpecialPrice = lazy(() => import("pages/UpdateSpecialPrice"));
const UpdateNightPrice = lazy(() => import("pages/UpdateNightPrice"));

const UserDetails = lazy(() => import("pages/UserDetails"));
const DriverDetails = lazy(() => import("pages/DriverDetails"));
const EventsDetails = lazy(() => import("pages/EventsDetails"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));
const VehicleDetails = lazy(() => import("pages/VehicleDetails"));
const PartnerDetails = lazy(() => import("pages/PartnerDetails"));
const CompanyDetails = lazy(() => import("pages/CompanyDetails"));

const Dashboard = lazy(() => import("pages/Dashboard"));
const Zone = lazy(() => import("pages/Zone"));
const Users = lazy(() => import("pages/Users"));
const Login = lazy(() => import("pages/Login"));
const Events = lazy(() => import("pages/Events"));
const Roles = lazy(() => import("pages/Roles"));
const Lohns = lazy(() => import("pages/Lohn"));
const Quotations = lazy(() => import("pages/Quotations"));
const Models = lazy(() => import("pages/Models"));
const Pricing = lazy(() => import("pages/Pricing"));
const Drivers = lazy(() => import("pages/Drivers"));
const Careers = lazy(() => import("pages/Careers"));
const Bookings = lazy(() => import("pages/Bookings"));
const Vehicles = lazy(() => import("pages/Vehicles"));
const Partners = lazy(() => import("pages/Partners"));
const Companies = lazy(() => import("pages/Companies"));
const Invoices = lazy(() => import("pages/Invoices"));
const Contacts = lazy(() => import("pages/Contacts"));
const Campaigns = lazy(() => import("pages/Campaigns"));
const Commission = lazy(() => import("pages/Commission"));
const Applicants = lazy(() => import("pages/Applicants"));
const UsersPoints = lazy(() => import("pages/UsersPoints"));
const ServiceClass = lazy(() => import("pages/ServiceClass"));
const SearchHistory = lazy(() => import("pages/SearchHistory"));
const SpecialPrices = lazy(() => import("pages/SpecialPrices"));
const Notifications = lazy(() => import("pages/Notifications"));
const BookingRatings = lazy(() => import("pages/BookingRatings"));
const CompanyInvoices = lazy(() => import("pages/CompanyInvoices"));
const CustomerInvoices = lazy(() => import("pages/CustomerInvoices"));
const CompanyRequests = lazy(() => import("pages/CompanyRequests"));
const PartnerRequests = lazy(() => import("pages/PartnerRequests"));
const CampaignDetails = lazy(() => import("pages/CampaignDetails"));
const ReportedProblems = lazy(() => import("pages/ReportedProblems"));
const Dynamics = lazy(() => import("pages/Dynamics"));
const Availability = lazy(() => import("pages/Availability"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [
  { path: "/dashboard", element: <Dashboard /> },

  { path: "/bookings", element: <Bookings /> },
  { path: "/add-booking", element: <AddBooking /> },
  { path: "/update-booking/:id", element: <AddBooking /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },
  { path: "/add-script", element: <AddScript /> },
  { path: "/update-script/:id", element: <AddScript /> },

  { path: "/users", element: <Users /> },
  { path: "/user-points/:id", element: <UsersPoints /> },
  { path: "/user-details/:id", element: <UserDetails /> },

  { path: "/chauffeurs", element: <Drivers /> },
  { path: "/add-chauffeur", element: <AddDriver /> },
  { path: "/update-chauffeur/:id", element: <AddDriver /> },
  { path: "/chauffeur-details/:id", element: <DriverDetails /> },

  { path: "/vehicles", element: <Vehicles /> },
  { path: "/add-vehicle", element: <AddVehicle /> },
  { path: "/update-vehicle/:id", element: <AddVehicle /> },
  { path: "/vehicle-details/:id", element: <VehicleDetails /> },

  { path: "/models", element: <Models /> },
  { path: "/add-model", element: <AddModel /> },
  { path: "/update-model/:id", element: <AddModel /> },

  { path: "/class", element: <ServiceClass /> },
  { path: "/add-class", element: <AddClass /> },
  { path: "/update-class/:id", element: <AddClass /> },

  { path: "/Zone", element: <Zone /> },
  { path: "/add-zone", element: <AddZone /> },
  { path: "/update-zone/:id", element: <AddZone /> },

  { path: "/partners", element: <Partners /> },
  { path: "/add-partner", element: <AddPartner /> },
  { path: "/update-partner/:id", element: <AddPartner /> },
  { path: "/partner-details/:id", element: <PartnerDetails /> },

  { path: "/partner-requests", element: <PartnerRequests /> },

  { path: "/companies", element: <Companies /> },
  { path: "/add-company", element: <AddCompany /> },
  { path: "/update-company/:id", element: <AddCompany /> },
  { path: "/company-details/:id", element: <CompanyDetails /> },

  { path: "/company-requests", element: <CompanyRequests /> },

  { path: "/campaigns", element: <Campaigns /> },
  { path: "/priority", element: <Priority /> },
  { path: "/update-priority/:id", element: <PriorityDetails /> },
  { path: "/add-campaign", element: <AddCampaign /> },
  { path: "/update-campaign/:id", element: <AddCampaign /> },
  { path: "/campaign-details/:id", element: <CampaignDetails /> },

  { path: "/pricing", element: <Pricing /> },
  { path: "/add-pricing", element: <AddPricing /> },
  { path: "/update-pricing/:id", element: <AddPricing /> },

  { path: "/special-price", element: <SpecialPrices /> },
  { path: "/add-special-price", element: <AddSpecialPrice /> },
  { path: "/update-special-price/:id", element: <UpdateSpecialPrice /> },

  { path: "/night-price", element: <NightPrices /> },
  { path: "/add-night-price", element: <AddNightPrice /> },
  { path: "/update-night-price/:id", element: <UpdateNightPrice /> },

  { path: "/partner-invoices", element: <Invoices /> },
  { path: "/company-invoices", element: <CompanyInvoices /> },
  { path: "/customer-invoices", element: <CustomerInvoices /> },

  { path: "/careers", element: <Careers /> },
  { path: "/add-career", element: <AddCareer /> },
  { path: "/update-career/:id", element: <AddCareer /> },
  { path: "/careers/:id", element: <Applicants /> },

  { path: "/Notifications", element: <Notifications /> },
  { path: "/send-push-notification", element: <SendPushNotification /> },
  { path: "/update-push-notification/:id", element: <SendPushNotification /> },

  { path: "/events", element: <Events /> },
  { path: "/event-details/:id", element: <EventsDetails /> },

  // { path: "/payroll", element: <PayRoll /> }, // commented cause , no need to show now
  { path: "/contacts", element: <Contacts /> },
  { path: "/commission", element: <Commission /> },
  { path: "/ratings", element: <BookingRatings /> },
  { path: "/search-history", element: <SearchHistory /> },
  { path: "/reported-problems", element: <ReportedProblems /> },

  // { path: "/extras", element: <Products /> },  // commented cause , no need to show now
  { path: "/add-product", element: <AddProduct /> },
  { path: "/update-product/:id", element: <AddProduct /> },

  { path: "/lohns", element: <Lohns /> },
  { path: "/quotations", element: <Quotations /> },
  { path: "/add-quotation", element: <AddQuotation /> },
  { path: "/update-quotation/:id", element: <AddQuotation /> },

  { path: "/roles", element: <Roles /> },
  { path: "/add-role", element: <AddRole /> },
  { path: "/update-role/:id", element: <AddRole /> },
  { path: "/add-role-permission", element: <AddRolesPermissions /> },
  { path: "/update-role-permission/:id", element: <AddRolesPermissions /> },

  { path: "/add-company-account", element: <AddCompanyAccount /> },
  { path: "/update-company-account/:id", element: <AddCompanyAccount /> },

  { path: "/dynamics", element: <Dynamics /> },
  
  { path: "/availability", element: <Availability /> },
];
