import { Suspense, useEffect, useState } from "react";
import PrivateRoute from "./PrivateRoute";
import { public_routes, private_routes, IRoute } from "routes";
import CircleLoader from "components/atoms/CircleLoader";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";

export default function AppRoutes() {

	const routes = useAppSelector((state) => state.auth?.user?.role_id?.routes);
	const [routesAccess, setRoutesAccess] = useState<IRoute[]>([])
	const [loading, setLoading] = useState<Boolean>(true)
	useEffect(() => {
		if (typeof routes === "object") {
			let private_routesCheck = private_routes.filter((item) => routes.includes(item.path))
			setRoutesAccess(private_routesCheck)
			setLoading(false)
		} else {
			AuthService.logout()
			setLoading(false)
		}

	}, [routes])

	return (
		<div style={{ minHeight: "90vh", position: "relative" }}>
			{loading ? <CircleLoader /> :

				<Suspense fallback={<CircleLoader />}>
					<Routes>
						{public_routes.map(({ path, element }, i) => (
							<Route key={i} path={path} element={element} />
						))}

						{routesAccess.map(({ path, element }, i) => (
							<Route
								key={i}
								path={path}
								element={<PrivateRoute>{element}</PrivateRoute>}
							/>
						))}

						<Route path="*" element={<Navigate to="/" />} />
						{/* <Route path="*" element={<NotFound />} /> */}
					</Routes>
				</Suspense>
			}
		</div>
	);
}
